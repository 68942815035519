<template>
  <div class="wrapper">
    <div ref="container">
    </div>
    
    <div class="connect-form">
      <div>{{ meetingNumber }}</div>
      <v-text-field v-model="password" placeholder="Password"></v-text-field>
      <v-btn block @click="joinMeeting">Connect</v-btn>
    </div>

    
  </div>
</template>

<script>
import ZoomClient from '@zoomus/websdk/embedded';

import awsAuth from '@/cognito/aws-auth';
import agoraRequest from '@/services/agora-request'
import loader from '@/config.loader';


const client = ZoomClient.createClient();
const apiKey = loader.getConfigValue('VUE_ZOOM_KEY');

export default {
  data() {
    return {
      username: null,
      password: null,
      meetingNumber: null,
      connecting: false
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    async joinMeeting() {
      this.connecting = true;

      try {
        await this.join({ 
          meetingNumber: this.meetingNumber, 
          password: this.password,
          signature: await this.getSignature()
        });
      } catch (err) {
        this.connecting = false;
      }
    },

    async getSignature() {
      const response = await agoraRequest({
        method: 'POST',
        url: `/zoom/signature?meeting=${this.meetingNumber}`,
      });

      return response.data.signature;
    },

    async join(payload) {
      await client.join({ apiKey, userName: this.username, ...payload });
    },

    async init() {
      await this.initClient();

      this.getUsername();
      this.getMeetingNumber();
    },

    async initClient() {
      await client.init({
        debug: true,
        zoomAppRoot: this.$refs.container,
        language: 'en-US',
        customize: {
          meetingInfo: ['topic', 'host', 'mn', 'pwd', 'telPwd', 'invite', 'participant', 'dc', 'enctype'],
          toolbar: {
            buttons: [
              { text: 'Custom Button', className: 'CustomButton', onClick: () => { console.log('custom button'); } }
            ]
          }
        }
      });
    },

    getUsername() {
      this.username = awsAuth.currentUser().getUsername();
    },

    getMeetingNumber() {
      const meeting = this.$route.params.id;

      if (meeting) {
        this.meetingNumber = meeting;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 2rem;

  width: 100%;
  height: 100%;
  
  display: grid;

  place-content: center;
}

.connect-form {
  width: 400px;
  height: 100%;


  margin: auto;
}
</style>
